import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'


const MyContextProvider = ({children}) => {
    const [enquiryData, setEnquiryData] = useState([]);
    const [passportData, setPassportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count,setCount] = useState(0)
  
    useEffect(() => {
        fetchData();
        passdata()
      }, []);
    
      const fetchData = async () => {
        setLoading(true);
    
        try {
          const response = await fetch('https://bottom-seven.vercel.app/enquiry');
          const data = await response.json();
    
          // Sort data in descending order based on _id (assuming _id is a string)
          const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
    
          // Calculate the number of new enquiries
          const newEnquiries = sortedData.filter(item => !enquiryData.some(existingItem => existingItem._id === item._id));
    
    
          setCount(newEnquiries.length);
    
          setEnquiryData(sortedData);
        } catch (error) {
          console.error('Error fetching enquiry data:', error);
          // Handle the error appropriately
        } finally {
          setLoading(false);
        }
      };


      
    
      const passdata = async () => {
        setLoading(true);
    
        try {
          const response = await fetch('https://bottom-seven.vercel.app/passport-info');
          const data = await response.json();
    
          // Sort data in descending order based on _id (assuming _id is a string)
          const sortedData = data.sort((a, b) => b._id.localeCompare(a._id));
    
        
    
    
         
    
          setPassportData(sortedData);
        } catch (error) {
          console.error('Error fetching enquiry data:', error);
          // Handle the error appropriately
        } finally {
          setLoading(false);
        }
      };


return (
<MyContext.Provider value={{setCount,enquiryData,loading,count,passportData}} >
{children}
</MyContext.Provider>
)
}

export default MyContextProvider