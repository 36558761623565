// Sidebar.js
import React, { useContext } from 'react';
import { BiBody } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { Link, useLocation } from 'react-router-dom';
import { IoMdDocument, IoMdGitNetwork } from "react-icons/io";
import MyContext from './MyContext';


const Sidebar = () => {

  const{count,setCount} = useContext(MyContext)

  const location = useLocation()

  if (location.pathname === '/'){
    return null
  }
   
  return (
    <div className="h-screen bg-gray-800 text-white w-1/4">
      <div className="p-4">
        <h1 className="text-2xl font-bold">V-Ex Tech Solution</h1>
      </div>
      <nav className="mt-4">
        <ul>
          <li className="mb-2">
            <Link to="/enquiry" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2 relative" onClick={() => setCount(0)}>
              Enquiry
              <span className="mr-2">
              
                <BiBody size={25} />
               
              </span>

              <span className="absolute top-2 right-5 bg-red-500 text-white rounded-full px-2">
                    {count}
                  </span>
             
            </Link>
          </li>
          <li className="mb-2">
          <Link to="/attendance" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
          Attendance
            <span className="mr-2">
              <BiCalendar size={25} />
            </span>
          </Link>
        </li>
        <li className="mb-2">
        <Link to="/work-done" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
          Work Done
          <span className="mr-2">
            <IoMdGitNetwork size={25} />
          </span>
        </Link>
      </li>
      <li className="mb-2">
      <Link to="/students" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
        Students
        <span className="mr-2">
          <BiBody size={25} />
        </span>
      </Link>
    </li>

    <li className="mb-2">
    <Link to="/passport-info" className="flex items-center p-2 bg-gray-700 hover:bg-gray-600 gap-2">
     Passport
      <span className="mr-2">
        <IoMdDocument size={25} />
      </span>
    </Link>
  </li>
   
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
