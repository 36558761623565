// Check.js
import React, { useState, useEffect } from 'react';



const Check = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch('https://bottom-seven.vercel.app/attendance');
      const data = await response.json();
      setAttendanceData(data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);

    try {
      const response = await fetch(`https://bottom-seven.vercel.app/attendance/search?name=${searchName}`);
      const data = await response.json();
      setAttendanceData(data);
    } catch (error) {
      console.error('Error searching attendance data:', error);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  };

  return (
    
   
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
    
      <h2 className='text-2xl font-semibold mb-4'>Attendance Data</h2>
      <div className='search-check flex items-center mb-4'>
        <input
          type="text"
          placeholder="Search by name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          className='border p-2 mr-2'
        />
        <button onClick={handleSearch} className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'>
          Search
        </button>
      
      </div>

      {loading ? (
        <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
      ) : (
        <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Work</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map(item => (
              <tr
                key={item._id}
                className="bg-gray-100 hover:bg-yellow-200 transition duration-300"
              >
                <td className="border px-4 py-2">{item.name}</td>
                <td className="border px-4 py-2">{item.date}</td>
                <td className="border px-4 py-2">{item.work}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
   
  )
};

export default Check;
