import React, { useState, useEffect, useContext } from 'react';
import MyContext from './MyContext';

const Passport = () => {
  const { passportData, loading } = useContext(MyContext);
  const [selectedOption, setSelectedOption] = useState(() => {
    const savedOption = localStorage.getItem('selectedOption');
    return savedOption ? savedOption : 'all';
  });
  const [filteredData, setFilteredData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const today = new Date().toLocaleDateString('en-GB');

   

  useEffect(() => {
    const filtered = selectedOption === 'all' 
      ? passportData 
      : passportData.filter(item => item.idType === selectedOption);

    setFilteredData(filtered);

    // Save the selected option to local storage
    localStorage.setItem('selectedOption', selectedOption);
  

  }, [selectedOption, passportData]);

  const handleDelete = async (id) => {
    setIsDeleting(true);

    try {
      const response = await fetch('https://bottom-seven.vercel.app/passport-delete', {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.success) {
        alert(data.message);
        window.location.reload();
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
      <h2 className='text-2xl font-semibold mb-4'>My Work Data</h2>

      <select
        className='mb-4 p-2 border border-gray-300 rounded'
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value) || window.location.reload()}
      >
        <option value='all'>All</option>
        <option value='aadhar' >Aadhar Card</option>
        <option value='pan'>PAN Card</option>
        <option value='passport'>Passport</option>
        <option value='voter'>Voter ID</option>
        <option value='driving'>Driving Licence</option>
        <option value='students' >Students</option>
        <option value='other'>Other</option>
      </select>

      {loading || isDeleting ? (
        <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
      ) : (
        <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Message/Status</th>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(item => (
              <tr
                key={item._id}
                className="bg-gray-100 hover:bg-yellow-200 transition duration-300"
              >
                <td className="border px-4 py-2">{item.name}</td>
                <td className="border px-4 py-2">{item.work}</td>
                <td className="border px-4 py-2">{new Date(item.date).toLocaleDateString('en-GB')}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleDelete(item._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {filteredData.filter(item => new Date(item.date).toLocaleDateString('en-GB') === today).length >0 &&
  
        <div className="fixed bottom-4 left-4 p-6 bg-red-600 text-white rounded-lg shadow-lg max-w-xs">
          <h4 className="text-xl font-semibold mb-2">Complete Work</h4>
          <ul className="list-decimal pl-5 space-y-1">
            {filteredData
              .filter(item => 
                new Date(item.date).toLocaleDateString('en-GB') === today)
          
              .map((item, index) => (
              <li key={index} className="text-sm font-medium">
                {item.name}
              </li>
            ))
          
          
          }
          </ul>
        </div>

      }
   
    </div>
  );
};

export default Passport;
