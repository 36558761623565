import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

const Login = ({setLogin}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      if (values.username === 'admin' && values.password === 'Veer@2238') {
        
        setLogin(true)        
        navigate('/enquiry');
        
      } else {
        alert('Invalid credentials. Please try again.');
      }
    },
  });

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-500">
      <div className="bg-white p-8 rounded shadow-md w-96 mx-auto transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2">
        <h1 className="text-2xl font-bold text-center text-gray-800">
          V-Ex Tech Solution
        </h1>
        <div className="bg-white p-8 rounded shadow-md w-full">
          <h2 className="text-3xl font-semibold mb-4 text-center text-gray-800">
            Login
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-600">
                Username:
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                Password:
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="mt-1 p-2 border rounded-md w-full"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 w-full"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
