import React, { useState, useEffect } from 'react';

const Accept = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch('https://databack-tumt.vercel.app/accept');
      const data = await response.json();
      setEnquiryData(data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setError('Error fetching attendance data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (name, work) => {
    try {
      const response = await fetch('https://databack-tumt.vercel.app/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          work,
        }),
      });

      const data = await response.json();

      if (data.success) {
        alert(data.message);
        window.location.reload()
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const generateTableRows = (item) => {
    return item.works
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .filter((workItem) => workItem.link && workItem.link.length !== 0 && workItem.unison !== 'veer')
      .map((workItem, index) => (
        <React.Fragment key={`${item.name}-${workItem.work}`}>
          <td className="border px-4 py-2">{workItem.work}</td>
          <td className="border px-4 py-2">{workItem.date}</td>
          <td className="border px-4 py-2">{workItem.link}</td>
          <td className="border px-4 py-2">
            <button onClick={() => handleDelete(item.name, workItem.work)} className="bg-green-500 text-white py-1 px-2 rounded hover:bg-red-600">
              Accept
            </button>
          </td>
        </React.Fragment>
      ));
  };

  return (
    <div className='container mx-auto p-4 h-screen overflow-x-auto'>
    <h2 className='text-2xl font-semibold mb-4'>Work Data</h2>
        {loading ? (
          <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin mx-auto"></div>
        ) : (
          <table className="table-auto w-full bg-white rounded-lg overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="border px-4 py-2">Name</th>
                <th className="border px-4 py-2">Work</th>
                <th className="border px-4 py-2">Date</th>
                <th className="border px-4 py-2">Link</th>
                <th className="border px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {enquiryData.map((item) => (
                <tr className="bg-gray-100 hover:bg-yellow-200 transition duration-300" key={item.name}>
                  <td className="border px-4 py-2">{item.name}</td>
                  {generateTableRows(item)}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
  
  );
};

export default Accept;
