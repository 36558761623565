import React, { useEffect, useState } from 'react';
import { json } from 'react-router-dom';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS

const MyComponent = () => {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const handle = async (email,name) => {
    setLoading(true);
    try {
        const response = await fetch("https://bottom-seven.vercel.app/birthwish", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body:JSON.stringify({email:email,name:name})
        });

  
       

        const data = await response.json();

        if (data.success) {
            alert(data.message);
         
        } else {
            alert(data.error);
        }
    } catch (error) {
        alert(`Failed to send birthday wishes: ${error.message}`);
        console.error("Error sending birthday wishes:", error);
    } finally {
        setLoading(false);
    }
};


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://bottom-seven.vercel.app/api/get-file-data');
        const data = await response.json();
        setFileData(data);
      } catch (error) {
        console.error('Error fetching file data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const today = new Date();
  const todayString = `${today.getMonth() + 1}-${today.getDate()}`;
  const birthdayUsers = fileData.filter(user => {
    const userBirthday = new Date(user.dob);
    const userBirthdayString = `${userBirthday.getMonth() + 1}-${userBirthday.getDate()}`;
    return userBirthdayString === todayString;
  });

  const totalFees = fileData.reduce((acc, item) => acc + parseFloat(item.fees), 0);
  const totalFeesInRupees = totalFees.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });

  const filteredFileData = fileData.filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div className="container mx-auto p-4 h-screen overflow-x-auto bg-gray-100 text-gray-800">
      <h2 className="text-3xl font-semibold mb-6 text-center">Student Data</h2>
      <div className="mt-1 p-2 text-xl font-semibold text-black-500 text-right">
        Total Fees: {totalFeesInRupees}
      </div>
      <input
      type="text"
      placeholder="Search by name..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full p-2 my-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <div className="border-t-8 border-blue-500 border-solid rounded-full h-16 w-16 border-t-blue-600 animate-spin"></div>
        </div>
      ) : (
        <div>
          {birthdayUsers.length > 0 && (
            <div className="bg-green-200 p-4 rounded-md shadow-md mb-4">
              <h3 className="font-semibold text-blue-500">Birthdays Today:  
              
              </h3>
              <ol className="mt-4">
                {birthdayUsers.map(user => (
                  <>
                  <li key={user._id} className="text-gray-800">{user.name}</li>
                    <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                  onClick={()=>handle(user.email,user.name)}
                
                >
                  Wish
                </button>
                </>
                ))}
              </ol>
            </div>
          )}
          {filteredFileData.map((item) => (
          <div key={item._id} className="p-6 bg-white mb-8 rounded-md shadow-md">
            <ol className="list-none space-y-3">

            <li className="bg-yellow-100 rounded p-2">
                  <span className="font-semibold text-blue-500">Name:</span> {item.name}
                </li>
              <li>
                <span className="font-semibold text-blue-500">Passport Size Photo:</span>{' '}
                <a
                  href={item.photo}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="file1"
                  className="font-semibold text-green-600 hover:underline"
                >
                  {item.photo}
                </a>
              </li>
              <li>
                <span className="font-semibold text-blue-500">Aadhar card:</span>{' '}
                <a
                  href={item.aadhar}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="file2"
                  className="font-semibold text-green-600 hover:underline"
                >
                  {item.aadhar}
                </a>
              </li>
             
              <li>
                <span className="font-semibold text-blue-500">Mobile:</span> {item.mobile}
              </li>

              <li>
              <span className="font-semibold text-blue-500">Dob:</span> {formatDate(item.dob)}              </li>
                <li >
                  <span className="font-semibold text-blue-500">Email:</span> {item.email}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Permanent Address:</span> {item.permanentadd}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Present Address:</span> {item.presentadd}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Pincode:</span> {item.pincode}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Institute Name:</span> {item.institutename}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Education:</span> {item.education}
                </li>
                
                <li >
                  <span className="font-semibold text-blue-500">Current Status:</span> {item.currentstatus}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Technology Opted:</span> {item.techopted}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Duration:</span> {item.duration}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Fees:</span> {item.fees}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Referred By:</span> {item.referedby}
                </li>
                <li >
                  <span className="font-semibold text-blue-500">Created At:</span> {new Date(item.createdAt).toLocaleDateString()}
                </li>
              </ol>

        
            </div>
          ))}
        </div>
      )}
    
  
    </div>
    
  );
};

export default MyComponent;
